<template>
  <div class="protokoll-arbeitsbeschreibung">

    <h5 class="font-weight-bold" style="font-size: 1rem;">Arbeitsbeschreibung*</h5>

    <CRow class="form-group">
      <CCol sm="12">
        <ValidationProvider rules="required" immediate v-slot="{ errors }">
          <CTextarea
            rows="7"
            v-model="arbeitsbeschreibung"
            :isValid="errors.length ==  0"
            v-on:input="emitValue"
          >
            <template #invalid-feedback>
              <em class="error invalid-feedback">Das Feld darf nicht leer sein.</em>
            </template>

            <template #append>
              <CButton color="light" v-on:click="textbausteinModal = true">
                <CIcon name="cil-file" />
              </CButton>
            </template>
          </CTextarea>
        </ValidationProvider>
      </CCol>
    </CRow>

    <CModal
      title='Vorgabe Textbausteine'
      color="success"
      :show.sync="textbausteinModal"
    >
      <v-select
        v-model="filterValue"
        placeholder="Bitte wählen Sie einen Textbaustein aus ..."
        :options="cTextbausteine"
        v-on:input="inputEvent"
      >
        <template #no-options>
          Keine Textbausteine gefunden.
        </template>
      </v-select>
    </CModal>

  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'protokoll-arbeitsbeschreibung',
  data () {
    return {
      arbeitsbeschreibung: '',
      textbausteine: null,
      filterValue: null,
      textbausteinModal: false
    }
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  watch: {
    value: function () {
      this.arbeitsbeschreibung = this.value
    }
  },
  computed: {
    cTextbausteine () {
      const options = []
      this._.forEach(this.textbausteine, function (tb) {
        const tmp = {
          code: tb.textbausteinid,
          label: tb.name
        }
        options.push(tmp)
      })
      return options
    }
  },
  mounted () {
    if (this.value) {
      this.arbeitsbeschreibung = this.value
    }
    Vue.axios.get('/textbaustein/get')
      .then((response) => {
        this.textbausteine = response.data
      })
  },
  methods: {
    inputEvent: function (newValue) {
      if (newValue) {
        if (this.arbeitsbeschreibung !== '') {
          this.arbeitsbeschreibung = this.arbeitsbeschreibung + ' '
        }
        // Element finden
        const arrayid = Vue._.findIndex(this.textbausteine, function (tb) { return tb.textbausteinid === newValue.code })
        if (arrayid !== -1) {
          this.arbeitsbeschreibung = this.arbeitsbeschreibung + this.textbausteine[arrayid].text
        }
        //
        this.$emit('input', this.arbeitsbeschreibung)
      }
    },
    emitValue: function (newValue) {
      this.$emit('input', this.arbeitsbeschreibung)
    }
  }
}
</script>
