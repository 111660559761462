<template>
  <div class="protokoll-abgeschlossen">

    <strong>Abgeschlossen</strong>
    <StatusBoolean v-model="abgeschlossen" form-label-class="d-none"/>

  </div>
</template>

<script>
import StatusBoolean from '@/components/FormElements/Boolean'

export default {
  name: 'ProtokollStatus',
  components: {
    StatusBoolean
  },
  computed: {
  },
  mounted () {
  },
  data () {
    return {
      abgeschlossen: true
    }
  },
  watch: {
    abgeschlossen: function () {
      this.$emit('input', this.abgeschlossen)
    }
  },
  methods: {
  }
}
</script>
