<template>
  <div class="leistung">
    <label>Leistung*</label>
    <CSelect
      :value.sync="leistung"
      :isValid="calculateValidity()"
      :options="leistungenOptions"
      placeholder="Bitte wählen Sie eine Leistung"
      v-if="leistungen"
    >
      <template #append-content v-if="deleteButton">
          <span v-on:click="emptySelect" class="emptyDate">
            <CIcon name="cil-trash" />
          </span>
      </template>
    </CSelect>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'component-leistungsauswahl',
  props: {
    rules: {
      default: 'required',
      type: String
    },
    deleteButton: {
      default: false,
      type: Boolean
    },
    value: [Number, String]
  },
  computed: {
    leistungenOptions () {
      if (this.leistungen) {
        return this.leistungen.map(lst => {
          const result = {
            value: lst.leistungid,
            label: lst.name
          }

          return result
        })
      } else {
        return null
      }
    }
  },
  mounted () {
    this.loadLeistungen()
  },
  data () {
    return {
      leistung: null,
      leistungen: null
    }
  },
  watch: {
    leistung: function () {
      this.$emit('input', this.leistung)
    }
  },
  methods: {
    loadLeistungen () {
      Vue.axios.get('/leistung/get')
        .then((response) => {
          this.leistungen = response.data
        })
    },
    calculateValidity () {
      if (this.rules === '') {
        return true
      } else {
        if (!this.leistung) {
          return false
        } else {
          return true
        }
      }
    },
    emptySelect () {
      this.leistung = ''
    }
  }
}
</script>
